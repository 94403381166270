<template>
  <div>
    <Breadcrumbs />
    <section class="cart-page">
      <b-container>
        <b-row>
          <b-col md="8">
            <h2 class="page-title">{{ $t("shopping_basket") }}</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <div v-if="isLoading" class="box">
              <div class="loader"></div>
            </div>
            <div class="cart-item-wrap" v-else>
              <div
                v-for="cartItem of cartItems"
                :key="cartItem.id"
                class="cart-item-row"
              >
                <b-link :to="`/${cartItem.product.url_key}`">
                  <div
                    class="product-img"
                    :style="{
                      backgroundImage: `url(${cartItem.product.image.url})`,
                    }"
                  ></div>
                </b-link>

                <div class="product-info">
                  <div class="product-name-wrap">
                    <b-link :to="`/${cartItem.product.url_key}`">
                      <span class="product-name">{{
                        cartItem.product.name
                      }}</span></b-link
                    >

                    {{ filterProducts(cartItem.product.sku) }}
                    <ServicePostcodeValidation
                      @add-services-parent="getServicesFromParent"
                      :cartItem="cartItem"
                    ></ServicePostcodeValidation>

                    <div
                      v-if="typeof cartItem.configurable_options != 'undefined'"
                    >
                      <div
                        v-for="(option, index) of cartItem.configurable_options"
                        :key="index"
                        class="d-flex align-items-center mb-7"
                      >
                        <span class="size">{{ option.option_label }}: </span>
                        <span class="size-box">{{ option.value_label }}</span>
                        <span
                          class="product--price text-warning d-md-none ml-20"
                          >{{
                            formatPrice(
                              cartItem.prices.price.value * cartItem.quantity
                            )
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="product-extra">
                    <div class="pro-action-wrap">
                      <div class="quantity-input d-flex">
                        <div class="quantity-wrap">
                          <div class="action-wrap">
                            <b-link
                              class="step-down-btn"
                              @click="increaseQty(cartItem.id)"
                              ><i class="fas fa-sort-up"></i
                            ></b-link>

                            <b-link
                              class="step-up-btn"
                              @click="decreaseQty(cartItem.id)"
                              ><i class="fas fa-sort-down"></i
                            ></b-link>
                          </div>
                          <div class="action-output">
                            <!-- :value="cartItem.quantity" -->
                            <input
                              type="number"
                              min="1"
                              name="quantity"
                              :value="cartItem.quantity"
                              :ref="'inputQuantity' + cartItem.id"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div class="trash-icon">
                        <b-icon
                          icon="trash"
                          @click="removeProduct(cartItem.id)"
                          class="remove-row"
                        ></b-icon>
                      </div>
                    </div>

                    <div class="product-price">
                      <span :ref="'price' + cartItem.id">
                        {{
                          formatPrice(
                            cartItem.prices.row_total_including_tax.value
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <!-- <b-col md="5" lg="4" class="coupon-discount">
                <span class="d-block">{{ $t("coupon_discount") }}</span>
                <form action="#" class="d-flex">
                  <input
                    type="text"
                    class="flex-grow-1"
                    name="discount-code"
                    :placeholder="$t('fill_out_discount_code')"
                    v-model="couponCode"
                  />
                  <b-button @click="addCoupon" variant="warning">Bestel nu</b-button>
                </form>
              </b-col> -->
            <div class="discount-and-total">
              <ul class="prices">
                <li v-if="defaultShipping != null" class="">
                  <span class="name"
                    >{{ $t("shipping_cost") }}
                    {{ defaultShipping.carrier_title }}</span
                  >
                  <span
                    v-if="typeof defaultShipping.amount != 'undefined'"
                    class="cost ml-10"
                    >{{ formatPrice(defaultShipping.amount.value) }}</span
                  >
                </li>
                <li
                  class=""
                  v-for="(discount, index) in cartTotalDiscounts"
                  :key="index"
                >
                  <span class="name">{{ discount.label }}</span>
                  <span class="cost ml-10"
                    >- {{ formatPrice(discount.amount.value) }}</span
                  >
                </li>
                <li
                  class=""
                  v-for="(price, index) in cartTotalTaxPrices"
                  :key="index"
                >
                  <span class="name">{{ price.label }}</span>
                  <span class="cost ml-10">{{
                    formatPrice(price.amount.value)
                  }}</span>
                </li>
              </ul>
              <div class="total-wrap">
                <span class="total">{{ $t("total") }}</span>
                <span class="total-price ml-10">
                  {{ formatPrice(cartTotalPrice) }}
                </span>
              </div>
              <div class="checkout-btn">
                <b-button
                  @click="$router.push('/checkout')"
                  variant="primary"
                  class="text-light py-6 pr-20 pl-15"
                >
                  <span>{{ $t("order_now") }}</span>
                  <i class="fas fa-shopping-cart"></i
                ></b-button>
              </div>
            </div>
            <ul class="note">
              <li>
                <i class="fas fa-check-circle"></i
                ><label>
                  Afhalen in
                  <div class="color">9 winkels</div>
                </label>
              </li>
              <li>
                <i class="fas fa-check-circle"></i
                ><label
                  ><div class="color">Gratis bezorging</div>
                  onze regio vanaf € 25,</label
                >
              </li>
              <li>
                <i class="fas fa-check-circle"></i
                ><label
                  >Niet goed? Geld terug!
                  <div class="color">14 dagen bedenktijd</div></label
                >
              </li>
              <li>
                <i class="fas fa-check-circle"></i>
                <label
                  >Klanten beoordelen ons met een
                  <div class="color">9.1</div></label
                >
              </li>
            </ul>
            <!-- <div class="free_shipping">
              {{
                $t("free_shipping_msg", {
                  remain: formatPrice(freeShippingAmount)
                })
              }}
            </div> -->
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- <section class="more-products" v-if="related && related.length > 0">
      <div class="container">
        <h3 class="section-title">
          Nog niet compleet? Misschien zoek je deze producten
        </h3>
        <ClientOnly>
          <ProductSlider
            v-if="related.length"
            :productList="related"
            :productToShow="4"
            :sliderResponsive="[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  arrows: true,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  arrows: true,
                },
              },
              {
                breakpoint: 540,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  arrows: true,
                },
              },
            ]"
          />
        </ClientOnly>
      </div>
    </section> -->
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs.vue";
import ServicePostcodeValidation from "@/base/core/components/products/product-detail/ServicePostcodeValidation.vue";

//import ClientOnly from "vue-client-only";

import Cart from "@storefront/core/modules/cart/mixins";
import { mapGetters } from "vuex";
//import ProductSlider from "@/base/core/components/products/product-detail/ProductSlider.vue";

export default {
  name: "CartPage",
  components: {
    Breadcrumbs,
    ServicePostcodeValidation,
  },
  mixins: [Cart],
  data() {
    return {
      noOfItems: 1,
      couponCode: "",
      prices: [
        {
          id: "price1",
          name: "Verzendkosten (Verzenden - Via PostNL of DHL)",
          cost: "€ 0,00",
          timeDelivery: "Levertijd: 2 tot 4 werkdagen.",
        },
      ],
      allCartItems: {},
      producten: [],
      product: {},
      pigeonLoading: false,
      isLoading: false,
      emptyProduct: {},
    };
  },
  computed: {
    ...mapGetters({
      related: "product/getRelated",
    }),
    freeShippingAmount() {
      return this.$store.getters["cart/getFreeShippingAmount"];
    },

    currentProperties() {
      return this.product;
    },
  },
  created() {
    // this.loadProductOptions()
    this.fetchPigeons();
  },
  methods: {
    filterProducts(filtersku) {
      this.producten.forEach((product) => {
        // arr.push(value);
        if (product.sku == filtersku) {
          this.product = product;
          //this.product = {}
          //  return product
          //return 'test'
        }
      });
    },
    async fetchPigeons() {
      await this.cartItems.forEach((product) => {
        this.pigeonLoading = true;

        this.$store
          .dispatch("product/loadWithSku", {
            sku: product.product.sku,
          })
          .then((res) => {
            this.loadPigeonsData(res);
          });
      });
      this.pigeonLoading = false;
    },
    loadPigeonsData(data) {
      this.pigeons = data;
      this.producten.push(data);

      // this.producten.forEach((product) => {
      //   // arr.push(product);
      // });
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    increaseQty(id) {
      const qty = parseInt(this.$refs["inputQuantity" + id][0].value);
      this.$refs["inputQuantity" + id][0].value = qty + 1;
      this.quantityUp(id, qty);
    },
    decreaseQty(id) {
      const qty = parseInt(this.$refs["inputQuantity" + id][0].value);
      if (qty > 1) {
        this.$refs["inputQuantity" + id][0].value = qty - 1;
        this.quantityDown(id, qty);
      }
    },
    getServiceLabels(option) {
      if (option) {
        return option.values.map((val) => val.label).toString();
      }
    },
    formatPrice(price) {
      return this.$helpers.formatCurrency(price).replace(/\s/g, "");
    },
    async getServicesFromParent(payload) {
      this.selectedOptions = payload.services;
      this.selectedExtraPrice = payload.selectedExtraPrice;
      this.isLoading = true;

      await this.addToCart(payload.cartItem, payload.productType);
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
    },
    removeProduct(cartItem) {
      const cartItemCount = this.$store.getters["cart/getItemsTotalQuantity"];
      this.removeItem(cartItem);
      if (cartItemCount - 1 == 0) {
        this.$store.commit("product1/setServicePostcode", "");
      }
    },
    async addToCart(cartItem, productType) {
      let product = cartItem.product;
      let quantity = cartItem.quantity;
      if (productType) {
        this.removeItem(cartItem.id);
      }
      this.cartItems.push();
      switch (productType) {
        case "SimpleProduct": {
          let items =
            "[{ quantity:" + quantity + ' sku: "' + product.sku + '" ';
          let custOptions = "";

          if (this.selectedOptions.length > 0) {
            custOptions = " selected_options: [";
            this.selectedOptions.forEach((option) => {
              custOptions = custOptions + '"' + option.uid + '",';
            });
            custOptions = custOptions + "]";
          }

          items = items + custOptions + "}]";
          /*
          let item =
            '{data: {sku: "' +
            product.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let custOptions =""
          if (this.selectedOptions.length>0){
            custOptions =",customizable_options: [";
            this.selectedOptions.forEach.forEach((option, idx) => {

            });
            custOptions = custOptions + "]";
          }
          item = item + custOptions;
          */
          let retval = await this.$store.dispatch("cart/addProductToCart", {
            items: items,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToCart");
              }
              if (!this.$gtm.uaDisabled) {
                window.dataLayer.push({
                  event: "eec.add",
                  ecommerce: {
                    add: {
                      products: [
                        {
                          name: product.name,
                          id: product.sku,
                          quantity: quantity,
                        },
                      ],
                    },
                  },
                });
              }
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems],
                    },
                  },
                });
              }
            }
            // this.$bvModal.show("addToCart");
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: product.__typename,
            item: item,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToCart");
              }
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: product.name,
                        id: product.sku,
                        quantity: quantity,
                      },
                    ],
                  },
                },
              });
            }
            if (this.$gtm.ga4Enabled) {
              //GA4 event
              window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  add: {
                    currency: "EUR",
                    value: this.finalPrice,
                    items: [this.getGTMItems],
                  },
                },
              });
            }
            // this.$bvModal.show("addToCart");
          }
          break;
        }
        default:
          break;
      }
    },
  },
  mounted() {
    if (this.$gtm.enabled()) {
      if (this.$gtm.ga4Enabled) {
        let items = [];
        this.cartItems.forEach((element) => {
          let i = {
            item_id: element.product.sku,
            item_name: element.product.name,
            quantity: element.quantity,
            currency: "EUR",
            price: element.prices.price.value,
          };
          items.push(i);
        });
        //GA4 event
        window.dataLayer.push({
          event: "view_cart",
          ecommerce: {
            add: {
              currency: "EUR",
              value: this.cartTotalWithOutTax,
              items: items,
            },
          },
        });
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: "Winkelwagen" },
        { name: "keywords", content: "Winkelwagen" },
        { name: "description", content: "Winkelwagen" },
        { property: "og:title", content: "Winkelwagen" },
        { property: "og:description", content: "Winkelwagen" },
        { name: "twitter:title", content: "Winkelwagen" },
        { name: "twitter:description", content: "Winkelwagen" },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Winkelwagen",
    };
  },
};
</script>

<style lang="scss" scoped></style>
